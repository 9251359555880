import styled from 'styled-components'
import {
  Box,
  Flex
} from 'rebass/styled-components'
import { Link as GatsbyLink } from 'gatsby'

export const Wrapper = styled(Flex)`
  flex-direction: column;

  @media (max-width: ${props => props.theme.breakpoints[0]}) {
    flex-direction: row;
    flex-wrap wrap;
    justify-content: space-evenly;
  }

  &:after {
    border-bottom: thin solid ${props => props.theme.colors.sidebar.border};
    content: "";
    display: block;
    width: 25%;
    padding: 0 0 2rem;
    margin: 0 0 3rem;

    @media (max-width: ${props => props.theme.breakpoints[0]}) {
      margin: 0 0 1rem;
      padding: 0 0 1rem;
      width: 100%;
    }
  }
`

export const Item = styled(Box)`
  font-family: ${props => props.theme.font.body};
  font-weight: 300;
  font-size: 1.2rem;
  margin: 0 0 1rem;
`

export const ExternalLink = styled.a`
  color: ${props => props.theme.colors.sidebar.link};
  font-family: ${props => props.theme.font.body};
  font-weight: 300;
  font-size: 1.2rem;
`

export const Link = styled(GatsbyLink)`
  color: ${props => props.theme.colors.sidebar.link};
  font-family: ${props => props.theme.font.body};
  font-weight: 300;

  &.active {
    color: ${props => props.theme.colors.sidebar.activeLink};
  }
`
