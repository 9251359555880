import styled from 'styled-components'
import {
  Heading as SCHeading,
  Text as SCText
} from 'rebass/styled-components'

export const Heading = styled(SCHeading)`
  color: ${props => props.theme.colors.sidebar.text};
  font-family: ${props => props.theme.font.body};
  font-weight: 300;
  font-size: 3rem;
  padding: 8rem 0 0;

  &:after {
    border-bottom: thin solid ${props => props.theme.colors.sidebar.border};
    content: "";
    display: block;
    width: 75%;
    padding: 0 0 2rem;
    margin: 0 0 2rem;

    @media (max-width: ${props => props.theme.breakpoints[0]}) {
      margin: 0 0 1rem;
      padding: 0 0 1rem;
      width: 100%;
    }

  }

  @media (max-width: ${props => props.theme.breakpoints[0]}) {
    padding: 1rem 0;
    text-align: center;
  }
`

export const Text = styled(SCText)`
  font-family: ${props => props.theme.font.body};
  font-weight: 300;
  font-size: 1.2rem;

  &:after {
    border-bottom: thin solid ${props => props.theme.colors.sidebar.border};
    content: "";
    display: block;
    width: 50%;
    padding: 0 0 2rem;
    margin: 0 0 2rem;

    @media (max-width: ${props => props.theme.breakpoints[0]}) {
      padding: 0 0 1rem;
      padding: 0 0 1rem;
      width: 100%;
    }
  }
`
