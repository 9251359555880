import React, { FunctionComponent } from 'react'
import { useSiteMetadata } from '../../hooks/use-site-metadata'
import { Flex, Box } from 'rebass'
import { FaRss, FaTwitter, FaGithub, FaEnvelope } from 'react-icons/fa'

const SocialIcons: FunctionComponent = () => {
  const siteMetadata = useSiteMetadata()

  return (
    <Flex justifyContent='space-between'>
      <Box>
        <a href={`https://twitter.com/${siteMetadata.author.twitter as string}`} rel='noopener noreferrer' target='_blank'>
          <FaTwitter className='icon' title='View my Twitter account' />
        </a>
      </Box>
      <Box>
        <a href={siteMetadata.author.rss} rel='noopener noreferrer' target='_blank'>
          <FaRss className='icon' title='Subscribe to my RSS feed' />
        </a>
      </Box>
      <Box>
        <a href={`https://github.com/${siteMetadata.author.github as string}`} rel='noopener noreferrer' target='_blank'>
          <FaGithub className='icon' title='View my GitHub account' />
        </a>
      </Box>
      <Box>
        <a href={`mailto:${siteMetadata.author.email as string}`} rel='noopener noreferrer' target='_blank'>
          <FaEnvelope className='icon' title='Send me an email' />
        </a>
      </Box>
    </Flex>
  )
}

export default SocialIcons
