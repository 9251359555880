import React, { Fragment, FunctionComponent } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Wrapper, Item, Link } from './styled'
import { NavQuery } from '../../graphqlTypes'

const Nav: FunctionComponent = () => {
  const { pages } = useStaticQuery<NavQuery>(
    graphql`
      query Nav {
        pages: allFile(filter: {sourceInstanceName: {eq: "pages"}, internal: {mediaType: {eq: "text/markdown"}}}, sort: {fields: childMarkdownRemark___frontmatter___order, order: ASC}) {
          edges {
            node {
              relativeDirectory
              childMarkdownRemark {
                frontmatter {
                  title
                  nav
                  slug
                }
              }
            }
          }
        }
      }
    `
  )

  return (
    <Fragment>
      <Wrapper>
        { pages.edges.map((page, index) => {
          const slug = (page.node.childMarkdownRemark?.frontmatter?.slug !== null) ? page.node.childMarkdownRemark?.frontmatter?.slug : page.node.relativeDirectory
          const title = (page.node.childMarkdownRemark?.frontmatter?.nav !== null) ? page.node.childMarkdownRemark?.frontmatter?.nav : page.node.childMarkdownRemark?.frontmatter?.title

          return (
            <Item key={index}>
              <Link to={`/${slug as string}/`} activeClassName='active'>
                {title}
              </Link>
            </Item>
          )
        }) }
      </Wrapper>
    </Fragment>
  )
}

export default Nav
