import React, { ReactNode, FunctionComponent } from 'react'
import { Wrapper } from './styled'
import { Box } from 'rebass'

import Sidebar from '../Sidebar'
import Nav from '../Nav'
import SocialIcons from '../SocialIcons'

interface Props {
  children: ReactNode
}

const Layout: FunctionComponent<Props> = ({ children }: Props) => {
  return (
    <Wrapper>
      <Box
        width={[12 / 12, 2 / 12]}
      >
        <Sidebar />
        <Nav />
        <SocialIcons />
      </Box>
      <Box mx='auto' />
      <Box
        width={[12 / 12, 8 / 12]}
      >
        {children}
      </Box>
    </Wrapper>
  )
}

export default Layout
