import React, { Fragment, FunctionComponent } from 'react'
import { Link } from 'gatsby'

import { useSiteMetadata } from '../../hooks/use-site-metadata'
import {
  Heading,
  Text
} from './styled'

const Sidebar: FunctionComponent = () => {
  const siteMetadata = useSiteMetadata()

  return (
    <Fragment>
      <Heading><Link to='/'>{siteMetadata.title}</Link></Heading>
      <Text>{siteMetadata.description}</Text>
    </Fragment>
  )
}

export default Sidebar
