import { useStaticQuery, graphql } from 'gatsby'
import { SiteMetadataQuery } from '../graphqlTypes'

export const useSiteMetadata = () => {
  const { site: { siteMetadata } } = useStaticQuery<SiteMetadataQuery>(
    graphql`
      query siteMetadata {
        site {
          siteMetadata {
            title
            description
            author {
              email
              github
              rss
              twitter
            }
          }
        }
      }
    `
  )

  return siteMetadata
}
