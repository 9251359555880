import styled from 'styled-components'
import {
  Flex
} from 'rebass/styled-components'

export const Wrapper = styled(Flex)`
  flex-direction: row;
  overflow: hidden;
  margin: 0 auto;
  max-width: 1648px;
  padding: 0 8rem;

  @media (max-width: ${props => props.theme.breakpoints[0]}) {
    flex-direction: column;
    padding: 0 1rem;
  }
`
